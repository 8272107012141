<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="线下奖品兑换码" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="margin-top:46px;height: 100%;background: #fff;">
      <div class="h1">
        <span v-if="type == 1">赠送套餐：{{pname}}</span>
        <span v-if="type == 2">赠送优惠券：{{pname}}</span>
        <span v-if="type == 3">赠送储值卡：{{pname}}元</span>
        <span v-if="type == 4">赠送积分：{{pname}}分</span>
        <span v-if="type == 5">线下奖品：{{pname}}</span>
      </div>
      <div class="qy_code_wrapper">
        <vue-qr
          :text="downloadData.url"
          :margin="10"
          colorDark="#000"
          colorLight="#fff"
          :dotScale="1"
          :logoSrc="downloadData.icon"
          :logoScale="0.2"
          :size="240"
        ></vue-qr>
      </div>
      <!-- <van-empty v-if="list.length < 1" description="暂无数据~" /> -->
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
import vueQr from "vue-qr";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {
    vueQr
  },
  data() {
    return {
      list: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: "0",
      id: this.$route.query.id,
      type: this.$route.query.type,
      pname: this.$route.query.pname,
      downloadData: {}
    };
  },
  methods: {
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        customerId: this.userInfo.customerId
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .weixiulist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    // this.onLoad();
    this.downloadData = {
      url:
        "http://customertest.car-posthouse.cn/prize_result.html?token=" +
        localStorage.getItem("Authorization") +
        "&id=" +
        this.id
      //   icon: require("../../assets/img/1.png") //二维码中间的图片,可以不设置
    };
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.qy_code_wrapper {
  margin-top: 30px;
  padding: 0 15px;
  text-align: center;
}
.anniu {
  height: 20px;
  font-size: 12px;
  float: right;
  margin-top: 10px;
}
.h1 {
  text-align: center;
  padding-top: 50px;
  color: #000093;
}
.chepaih {
  display: inline-block;
  background: #000093;
  color: #fff;
  padding: 0 2px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>